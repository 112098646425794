// const
const MAPBOX_TOKEN =
  // For security reasons, please avoid using the default public token provided by Mapbox as much as possible.
  // Instead, manually add a new token and apply URL restrictions.
  // (please refer to https://github.com/yihong0618/running_page/issues/643#issuecomment-2042668580)
  "pk.eyJ1IjoieWlob25nMDYxOCIsImEiOiJja2J3M28xbG4wYzl0MzJxZm0ya2Fua2p2In0.PNKfkeQwYuyGOTT_x9BJ4Q";

const MUNICIPALITY_CITIES_ARR = [
  "New Delhi",
  "Mumbai",
  "Kolkata",
  "Chennai",
  "Bangalore",
  "Hyderabad",
];
const MAP_LAYER_LIST = [
  "road-label",
  "waterway-label",
  "natural-line-label",
  "natural-point-label",
  "water-line-label",
  "water-point-label",
  "poi-label",
  "airport-label",
  "settlement-subdivision-label",
  "settlement-label",
  "state-label",
  "country-label",
];

const USE_GOOGLE_ANALYTICS = false;
const GOOGLE_ANALYTICS_TRACKING_ID = "";

// styling: set to `true` if you want dash-line route
const USE_DASH_LINE = true;
// styling: route line opacity: [0, 1]
const LINE_OPACITY = 0.4;
// styling: map height
const MAP_HEIGHT = 600;
// set to `false` if you want to hide the road label characters
const ROAD_LABEL_DISPLAY = true;
// set to `true` if you want to display only the routes without showing the map.
const PRIVACY_MODE = false;
// set to `false` if you want to make light off as default, only effect when `PRIVACY_MODE` = false
const LIGHTS_ON = true;

// IF you are outside India please make sure IS_INDIAN = false
const IS_INDIAN = true;
const USE_ANIMATION_FOR_GRID = false;
const INFO_MESSAGE = (yearLength, year) =>
  `Running Journey of ${yearLength} Years. I mostly record my runs on Strava. The table shows year ${year} data`;
const FULL_MARATHON_RUN_TITLE = IS_INDIAN ? "Full Marathon" : "Full Marathon";
const HALF_MARATHON_RUN_TITLE = IS_INDIAN ? "Half Marathon" : "Half Marathon";
const MORNING_RUN_TITLE = IS_INDIAN ? "Morning Run" : "Morning Run";
const MIDDAY_RUN_TITLE = IS_INDIAN ? "Midday Run" : "Midday Run";
const AFTERNOON_RUN_TITLE = IS_INDIAN ? "Afternoon Run" : "Afternoon Run";
const EVENING_RUN_TITLE = IS_INDIAN ? "Evening Run" : "Evening Run";
const NIGHT_RUN_TITLE = IS_INDIAN ? "Night Run" : "Night Run";

const RUN_TITLES = {
  FULL_MARATHON_RUN_TITLE,
  HALF_MARATHON_RUN_TITLE,
  MORNING_RUN_TITLE,
  MIDDAY_RUN_TITLE,
  AFTERNOON_RUN_TITLE,
  EVENING_RUN_TITLE,
  NIGHT_RUN_TITLE,
};

export {
  USE_GOOGLE_ANALYTICS,
  GOOGLE_ANALYTICS_TRACKING_ID,
  MAPBOX_TOKEN,
  MUNICIPALITY_CITIES_ARR,
  MAP_LAYER_LIST,
  IS_INDIAN,
  ROAD_LABEL_DISPLAY,
  INFO_MESSAGE,
  RUN_TITLES,
  USE_ANIMATION_FOR_GRID,
  USE_DASH_LINE,
  LINE_OPACITY,
  MAP_HEIGHT,
  PRIVACY_MODE,
  LIGHTS_ON,
};

const saffron = "rgb(255,153,51)"; // if you want to change the main color change here src/styles/variables.scss
const india_green = "rgb(19,136,8)";

// If your map has an offset please change this line
// issues #92 and #198
export const NEED_FIX_MAP = false;
export const MAIN_COLOR = saffron;
export const PROVINCE_FILL_COLOR = india_green;
export const COUNTRY_FILL_COLOR = "#FFFFFF"; // Assuming this is meant to complement the flag colors
