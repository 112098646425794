import React from 'react';
import { Component } from 'react';

const FailedLoadSvg = () => <div>Failed to load SVG</div>;

export const loadSvgComponent = async (stats, path) => {
  try {
    const module = await stats[path]();
    return { default: module.default || module };
  } catch (error) {
    console.error(error);
    return { default: FailedLoadSvg };
  }
};
