import React, { useState, useEffect } from 'react';
import YearStat from '../../components/YearStat';
import RunningStats from '../../components/RunningStats';
import useActivities from '../../hooks/useActivities';

const Dashboard = ({ selectedYear, isMobile }) => {
    const { years, activities } = useActivities();
    const [currentYear, setCurrentYear] = useState(selectedYear || years[0]);

    useEffect(() => {
        if (selectedYear) {
            setCurrentYear(selectedYear);
        }
    }, [selectedYear]); // React to changes in selectedYear

    const handleYearClick = (year) => {
        setCurrentYear(year);
    };

    return (
        <div className="dashboard">
            <RunningStats runs={activities}></RunningStats>
            <div className='yearly-stat'>
                {isMobile
                    ? years.filter((year) => year === currentYear).map((year) => (
                        <YearStat key={year} year={year} onClick={handleYearClick} />
                    ))
                    : years.map((year) => (
                        <YearStat key={year} year={year} onClick={handleYearClick} />
                    ))
                }
            </div>
        </div>
    );
};

export default Dashboard;
