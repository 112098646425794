import React from "react";
import "./Resume.scss";
import ResumeHeader from "../../components/Resume/ResumeHeader";
import ResumeItems from "../../components/Resume/ResumeItems";

const experiences = [
  {
    type: "Technical Skills",
    items: [
      {
        company: "Languages",
        descriptions: ["Java, Python, JS, Golang, Scala"],
      },
      {
        company: "Others",
        descriptions: [
          "Concurrency, Springboot, RxJava, Kafka, Solr, BigQuery, MongoDB, Redis, VectorDB, LLM, Prometheus, Grafana, Bash, Git",
        ],
      },
    ],
  },
  {
    type: "Work Experience",
    items: [
      {
        start_date: "Feb 21",
        end_date: "Present",
        company: "Quinbay a BliBli company",
        role: "Senior Software Engineer - (Search)",
        descriptions: [
          "Designed an ML model to rerank the Search query results based on the click signals and product attributes.",
          "Revamped product indexing pipeline for introduced granularity, increasing document collection size by 200%.",
          "Strategized split sharding & deployments for Solr collections to cater high throughput and 0 downtimes.",
          "Did a POC on Solr Learning To Rank to support reranking based on Natural language.",
          "Engineered a pipeline to score long-tail query results, enabling global A/B testing.",
        ],
      },
      {
        start_date: "Feb 20",
        end_date: "Feb 21",
        company: "Ericsson Global India",
        role: "Associate Engineer Trainee",
        descriptions: [
          "Developed internal dashboards for fetching equipment health details from different OEM servers.",
          "Tools: Java, SpringBoot, MySQL, JavaScript, VueJs.",
        ],
      },
      {
        start_date: "May 19",
        end_date: "Sept 19",
        company: "AKVO Atomospheric Systems",
        role: "Automation Engineer Consultant",
        descriptions: [
          "Increased efficiency and reduced failure rate to a significant 4% using sensors.",
          "Wrote Backend Services for collecting data in real time.",
          "Designed System Specification and Implemented automated controls using microcontroller.",
        ],
      },
    ],
  },
  {
    type: "Projects",
    items: [
      {
        start_date: "Feb 21",
        end_date: "Present",
        company: "Pocket",
        link: "https://pocket.akhileshk.in/",
        descriptions: [
          "Built an app for personal finance which finds all the expenses and investments.",
          "Used Python, flask, MongoDB and Vue3.",
        ],
      }, {
        start_date: "Feb 21",
        end_date: "Present",
        company: "Jumpstart",
        link: "https://jmpstart.netlify.app/",
        descriptions: [
          "Mock Interview platform built as a personal project. Wrote the PRD, designed wireframes and mockups.",
          "Designed the architecture, DB models, wrote backend service, UI client and deployment pipeline based on Docker, Jenkins, Netlify, AWS ECR and ECS. Used Vue3, Golang, MongoDb, SES.",
        ],
      },
      {
        start_date: "Dec 18",
        end_date: "May 20",
        company: "MetaQP | Fuzzy Question Paper Searching",
        link: "https://metajuit.github.io/metaqp",
        descriptions: [
          "Currently has 1000+ users, 5000+ search queries, and has served 1500+ downloads.",
        ],
      },
    ],
  },
  {
    type: "Leadership Skills",
    items: [
      {
        company: "Scrum Master - MissionHQ Team, Quinbay",
        descriptions: [
          "Contributed as the Scrum master for mutiple sprints resulting in efficient sprint delivery.",
        ],
      },
      {
        company: "Vice Chairperson - ACM Student Chapter JUIT",
        descriptions: [
          "Responsible for forming event policies, Administration and management of ACM Student Chapter of JUIT.",
        ],
      },
      {
        company: "Organizer - Techstars Startup Weekend",
        descriptions: [
          "Organized Techstars Startupweekend powered by Google for Entreprenuership as the Tech Lead.",
        ],
      },
    ],
  },
  {
    type: "Honours and Awards",
    items: [
      {
        role: "Spot Award - Quinbay Technologies ",
      },
      {
        role: "Finalist, National Entrepreneurship Challenge - 2019",
      },
    ],
  },
  {
    type: "Academics",
    items: [
      {
        start_date: "Jul 2016",
        end_date: "May 2020",
        role: "Jaypee University of Information Technology, Waknaghat",
        company: "B.Tech in Electronics and Communication Engineering",
      },
    ],
  },
];

const Resume = () => {
  return (
    <div className="resume">
      <ResumeHeader
        className="resume-header"
        websitewithttp="https://akhileshk.in"
        website="akhileshk.in"
        linkedin="https://linkedin.com/in/akhilesh-k"
        linkedinusername="akhilesh-k"
        github="https://github.com/akhilesh-k"
        githubusername="akhilesh-k"
        firstName="Akhilesh"
        lastName="Kumar"
        email="akhileshk.juit@gmail.com"
        phone="+91-9829754634"
        address="Bengaluru-560102"
      />
      <ResumeItems experiences={experiences} />
    </div>
  );
};

export default Resume;
