import React, { Component } from "react";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

import "./TimelinePage.scss";

const textColor = "#eee";
const bgColor = "#00171f";

class TimelinePage extends Component {
  renderTimelineItems = (items) => {
    return items.map((item, index) => (
      <TimelineItem
        dateInnerStyle={{ background: bgColor, color: textColor }}
        key={index}
        dateText={item.dateText}
      >
        {item.content.map((paragraph, idx) => (
          <p key={idx}>{paragraph}</p>
        ))}
      </TimelineItem>
    ));
  };

  render() {
    const timelineData = [
      {
        dateText: "February 2020",
        content: [
          "Data Science @",
          <a href="https://www.ericsson.com/en">Ericsson</a>,
        ],
      },
      {
        dateText: "Jan 2020",
        content: [
          "Runner Up, Dotslash 2020 Hackathon",
          "6th position, Hackverse 2020 Hackathon",
        ],
      },
      {
        dateText: "December 2019",
        content: [
          "Learnt React.js, Node.js, MongoDB",
          "Started Full Stack Journey.",
        ],
      },
      {
        dateText: "May 2019",
        content: [
          "Interned at Akvo. Automated Atmospheric Water Generators",
          <a href="https://www.coursera.org/account/accomplishments/specialization/YJ83STEXMXEE">
            "Deep Learning Specialization."
          </a>,
          "World Rank 29",
          <a href="https://www.hackerearth.com/challenges/competitive/brainwaves-machine-learning/">
            "Brainwaves 2019"
          </a>,
        ],
      },
      {
        dateText: "January 2019",
        content: [
          <a href="https://github.com/akhilesh-k/metaqp-test">
            "Built MetaQP{" "}
            <span role="img" aria-label="rocket">
              🚀
            </span>
            "
          </a>,
          "Elected as ACM Student Chapter Vice-Chairperson",
        ],
      },
      {
        dateText: "October 2018",
        content: ["Organised Hacktoberfest 💖", "Learnt JavaScript"],
      },
      {
        dateText: "August 2018",
        content: [
          <a href="http://communities.techstars.com/india/solan-himachal-pradesh-india/startup-weekend/13143#event-organizers">
            "Organized Startupweekend Solan."
          </a>,
          <a href="http://communities.techstars.com/india/solan-himachal-pradesh-india/startup-weekend/13143#event-organizers">
            "Did Email Marketing!"
          </a>,
          "Designed, Published, Analyzed and Marketed with Email.",
        ],
      },
      {
        dateText: "February 2018",
        content: [
          "National Entrepreneurship Challenge Finalist!",
          "Top 6 among 500 teams across the country at the National Entrepreneurship Challenge at IIT Bombay.",
        ],
      },
      {
        dateText: "Pretty much complete 2018",
        content: [
          "Machine Learning! Computer Vision! Contributed to OpenCV.",
          "Strong Open Source Advocate!!",
        ],
      },
      {
        dateText: "November 2017",
        content: [
          "1st Prize!! Smart City Hackathon. Built",
          <a href="https://github.com/akhilesh-k/cityiotary">"CityIoTary"</a>,
        ],
      },
      {
        dateText: "September-December 2017",
        content: [
          "Lot's of Robotics! ROS, SLAM and Odometry",
          <a href="https://www.coursera.org/account/accomplishments/certificate/9SSNE58D35EH">
            "Robotics Specialization"
          </a>,
        ],
      },
      {
        dateText: "August 2017",
        content: [
          "Machine Learning 101",
          <a href="https://www.coursera.org/account/accomplishments/certificate/9SSNE58D35EH">
            "Andrew Ng's Course"
          </a>,
        ],
      },
      {
        dateText: "January-May 2017",
        content: [
          "Learnt C++ and Python",
          "Lots of Competitive. IoT, Android and Python. Lot's of Robotics.",
        ],
      },
      {
        dateText: "July-December 2016",
        content: [
          "Introduction to the world of programming!",
          <a href="https://cs50.harvard.edu/x/2020/">"CS50 from Hrvard"</a>,
        ],
      },
      {
        dateText: "14 Billion Years Ago",
        content: [
          "Our whole universe was in a hot dense state",
          "Then nearly fourteen billion years ago expansion started. Wait...",
          "The Earth began to cool The autotrophs began to drool Neanderthals developed tools",
          "We built a wall (we built the pyramids) Math, science, history, unraveling the mystery That all started with the big bang!",
        ],
      },
    ];

    return (
      <div className="TimelinePage">
        <h1 className="title center serif">timeline</h1>
        <h3 className="subtitle center serif">
          find what I am upto these days!
        </h3>
        <Timeline lineColor={"#00171f"}>
          {this.renderTimelineItems(timelineData)}
        </Timeline>
      </div>
    );
  }
}

export default TimelinePage;
