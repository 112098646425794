import { useState } from 'react';

const useHover = () => {
    const [hovered, setHovered] = useState(false);
    const [timer, setTimer] = useState(null);

    const eventHandlers = {
        onMouseOver() {
            setTimer(setTimeout(() => setHovered(true), 500)); // 500ms delay
        },
        onMouseOut() {
            clearTimeout(timer);
            setHovered(false);
        },
    };

    return [hovered, eventHandlers];
};

export default useHover;
