export const indianCities = [
    {
        code: '110001',
        name: 'New Delhi',
        state: 'DL',
        district: '01',
    },
    {
        code: '110002',
        name: 'Central Delhi',
        state: 'DL',
        district: '02',
    },
    {
        code: '400001',
        name: 'Mumbai',
        state: 'MH',
        district: '01',
    },
    {
        code: '600001',
        name: 'Chennai',
        state: 'TN',
        district: '01',
    },
    {
        code: '700001',
        name: 'Kolkata',
        state: 'WB',
        district: '01',
    },
    {
        code: '500001',
        name: 'Hyderabad',
        state: 'TG',
        district: '01',
    },
    {
        code: '560001',
        name: 'Bangalore',
        state: 'KA',
        district: '01',
    },
    {
        code: '302001',
        name: 'Jaipur',
        state: 'RJ',
        district: '01',
    },
    {
        code: '600028',
        name: 'Madurai',
        state: 'TN',
        district: '02',
    },
    {
        code: '680001',
        name: 'Kochi',
        state: 'KL',
        district: '01',
    },
    {
        code: '226001',
        name: 'Lucknow',
        state: 'UP',
        district: '01',
    },
    {
        code: '462001',
        name: 'Bhopal',
        state: 'MP',
        district: '01',
    },
    {
        code: '751001',
        name: 'Bhubaneswar',
        state: 'OD',
        district: '01',
    },
    {
        code: '682001',
        name: 'Thiruvananthapuram',
        state: 'KL',
        district: '02',
    },
    {
        code: '221001',
        name: 'Varanasi',
        state: 'UP',
        district: '02',
    },
];
