import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";

import "./App.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import pages from "./routes"; 

ReactGA.initialize("UA-160443909-1");

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentPage = pages.find((page) => page.path === currentPath);

    if (currentPage) {
      document.title = currentPage.title; // Set the page title
  }
  }, []);
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch className="switch">
          {pages.map((page, index) => (
            <Route key={index} path={page.path} exact={page.exact}>
              <page.component />
            </Route>
          ))}
        </Switch>
        <Footer className="footer" />
      </Router>
    </div>
  );
};

export default App;
