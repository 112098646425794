export const yearStats = {};
const requireYearStats = require.context('./', false, /year_.*\.svg$/);

requireYearStats.keys().forEach((key) => {
    yearStats[key] = () => Promise.resolve({ default: requireYearStats(key).default });
});

export const totalStat = {};
const requireTotalStat = require.context('./', false, /(github|grid)\.svg$/);

requireTotalStat.keys().forEach((key) => {
    totalStat[key] = () => Promise.resolve({ default: requireTotalStat(key).default });
});
