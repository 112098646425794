import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./BookShelf.scss";

class BookShelf extends Component {
  currentMonthInWords() {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    return month;
  }
  state = {
    sections: [], // To store the data fetched from Google Sheets
  };

  componentDidMount() {
    this.goodReadsData = Object.entries(localStorage)
      .map(([key, value]) => {
        console.log(key);
        return key;
      })
      .filter((key) => key.includes("goodreadsdata"))
      .map((key) => {
        const month = key.split("-")[1];
        if (month === this.currentMonthInWords()) {
          console.log(key);
          const storedData = localStorage.getItem(key);
          return JSON.parse(storedData); // Deserialize the JSON string to an object/array
        } else {
          return null;
        }
      })
      .filter((data) => data !== null); // Filter out any null values

    if (this.goodReadsData && this.goodReadsData.length > 0) {
      const sections = this.goodReadsData[0]; // Assuming you're storing one object in `localStorage` per month
      this.setState({ sections });
      console.log(sections);
    } else {
      this.fetchBookDataFromSheet();
    }
  }

  async fetchBookDataFromSheet() {
    const apiKey = "AIzaSyDGbkte7G6CC7HTv-v0N1480Kej25IX6D0"; // Replace with your actual API key
    const spreadsheetId = "1R52N4u-Ds_SABTqQ2F_5B5QBpgbkOck0UXNkResiT2A"; // Your spreadsheet ID
    const range = "Sheet1!A2:Y282"; // Adjust the range to match your data
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Process the data and update state
      const sections = this.processSheetData(data.values);
      this.setState({ sections });
    } catch (error) {
      console.error("Error fetching data from Google Sheets:", error);
    }
  }
  processSheetData(data) {
    const sections = [];
    let currentSection = null;
    const excludeColumns = [2, 5]; // Exclude columns C and F

    data.forEach((row) => {
      const filteredRow = row.filter(
        (_, index) => !excludeColumns.includes(index)
      );
      if (filteredRow[0]) {
        // Assume the first column indicates a new section
        if (currentSection) sections.push(currentSection);
        currentSection = {
          title: filteredRow[24], // Adjust according to your data
          books: [],
        };
      }
      if (currentSection && filteredRow[1]) {
        currentSection.books.push({
          title: filteredRow[1], // Adjust index based on excluded columns
          link: filteredRow[2], // Adjust index based on excluded columns
          progress: filteredRow[3], // Adjust index based on excluded columns
          progressDate: filteredRow[4], // Adjust index based on excluded columns
          description: filteredRow[5], // Adjust index based on excluded columns
        });
      }
    });

    if (currentSection) sections.push(currentSection);
    console.log(sections);

    if (sections.length > 0) {
      // Serialize the `sections` array to a JSON string before storing in `localStorage`
      localStorage.setItem(
        `goodreadsdata-${this.currentMonthInWords()}`,
        JSON.stringify(sections)
      );
    }

    return sections;
  }

  renderBookSection(title, books) {
    return (
      <div
        className="column content"
        id={title.replace(/\s+/g, "-").toLowerCase()}
      >
        <h3 className="subtitle">
          {title} ({books.length})
        </h3>
        <ul>
          {books.map((book, index) => (
            <li key={index}>
              <h6>
                <a rel="noopener noreferrer" href={book.link} target="_blank">
                  {book.title}
                </a>
              </h6>
              {book.progress && (
                <p>
                  {" "}
                  Progress: {book.progress} as on {book.progressDate}
                </p>
              )}
              <p>{book.description}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { location } = this.props;
    const shelfType = location.pathname.split("/").pop();

    const title =
      shelfType === "bookshelf"
        ? "Bookshelf"
        : shelfType === "papershelf"
        ? "Papershelf"
        : "";
    const shelfDescription =
      shelfType === "bookshelf"
        ? "I read a few books every month around various topics and here are some of them that I recommend."
        : shelfType === "papershelf"
        ? "I try to read a paper every month around various topics and here are some of them that I implement or try to disect."
        : "";
    const bookSections = [
      {
        title: "Currently Reading",
        books: [
          {
            title: "Superintelligence by Nick Bostrom",
            link:
              "https://www.amazon.in/Superintelligence-Dangers-Strategies-Nick-Bostrom/dp/0198739834",
            progress: "54/352",
            progressDate: "12th January 2024",
            description:
              "This seminal book injects the topic of superintelligence into the academic and popular mainstream. What happens when machines surpass humans in general intelligence? Will artificial agents save or destroy us? In a tour de force of analytic thinking, Bostrom lays a foundation for understanding the future of humanity and intelligent life.",
          },
          {
            title: "Clean Code by Robert C. Martin",
            link:
              "https://www.amazon.in/Clean-Code-Handbook-Software-Craftsmanship-ebook/dp/B001GSTOAM",
            description:
              "A reference book of coding best practices/patterns for a healthy codebase with some case studies. I read this book after having about five years' professional coding experience. It changed how I approached code readability, testing, and maintenance.",
          },
          {
            title: "The First 90 Days by Michael D. Watkins",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
            description:
              "When joining a new company, your perception is shaped massively in your first 3 months. It takes a lot of work to change that perception later. This book is a practical guide on how to be conscious in focusing on getting up to speed, faster.",
          },
        ],
      },
      {
        title: "Life - In general",
        books: [
          {
            title:
              "The Score Takes Care of Itself: My Philosophy of Leadership",
            link:
              "https://www.amazon.in/14-Habits-Highly-Productive-Developers-ebook/dp/B08BF74RRG",
          },
          {
            title: "Humankind: A Hopeful History",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title: "Grief is the Thing with Feathers",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title:
              "The Almanack of Naval Ravikant: A Guide to Wealth and Happiness",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title: "Ways of Seeing",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title: "How to Connect",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title: "No One Belongs Here More Than You",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title: "Book of Longing",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
          {
            title: "So You Want to Talk About Race",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
        ],
      },
      {
        title: "Comics and Humour",
        books: [
          {
            title: "xkcd",
            link: "https://xkcd.com",
          },
          {
            title: "SMBC",
            link: "https://www.smbc-comics.com/",
          },
          {
            title: "PhD Comics",
            link: "https://phdcomics.com/comics.php",
          },
          {
            title: "The Oatmeal",
            link: "https://theoatmeal.com/comics",
          },
        ],
      },
      {
        title: "Concurrency and Multithreading",
        books: [
          {
            title: "Java Concurrency in Practice",
            link:
              "https://www.amazon.in/Java-Concurrency-Practice-1-Goetz/dp/9332576521",
          },
        ],
      },
      {
        title: "System Design",
        books: [
          {
            title: "Designing Data-Intensive Applications",
            link:
              "https://www.amazon.in/Designing-Data-Intensive-Applications-Reliable-Maintainable/dp/9352135245",
          },
          {
            title: "System Design Interview - By Alex Xu",
            link:
              "https://www.amazon.in/System-Design-Interview-insiders-Second/dp/B08CMF2CQF",
          },
        ],
      },
      {
        title: "Search Relevance",
        books: [
          {
            title: "Relevant search",
            link:
              "https://www.amazon.in/Relevant-Search-Mr-Doug-Turnbull/dp/161729277X/",
          },
          {
            title: "Introduction To Information Retrieval",
            link:
              "https://www.amazon.in/Introduction-Information-Retrieval-Manning-Christopher/dp/1107666392",
          },
          {
            title: "Solr In Action",
            link:
              "https://www.amazon.in/Solr-Action-Trey-Grainger/dp/1617291021",
          },
        ],
      },

      {
        title: "Coding",
        books: [
          {
            title: "Clean Code",
            link:
              "https://www.amazon.in/Clean-Code-Handbook-Software-Craftsmanship-ebook/dp/B001GSTOAM",
          },
          {
            title: "The Pragmatic Programmer",
            link:
              "https://www.amazon.in/Pragmatic-Programmer-Anniversary-journey-mastery/dp/9353949432",
          },
          {
            title: "Modern Java in action",
            link:
              "https://www.amazon.in/Modern-Java-Action-functional-programming/dp/1617293563",
          },
          {
            title: "Head First Design Patterns",
            link:
              "https://www.amazon.in/Head-First-Design-Patterns-Object-Oriented/dp/9385889753",
          },
        ],
      },
      {
        title: "Interview Prepation",
        books: [
          {
            title: "Cracking the Coding Interview",
            link:
              "https://www.amazon.in/14-Habits-Highly-Productive-Developers-ebook/dp/B08BF74RRG",
          },
        ],
      },
      {
        title: "Developer Productivity",
        books: [
          {
            title: "14 Habits of Highly Productive Developers",
            link:
              "https://www.amazon.in/14-Habits-Highly-Productive-Developers-ebook/dp/B08BF74RRG",
          },
          {
            title: "The First 90 Days",
            link: "https://www.amazon.in/First-90-Days-Watkins/dp/1422188612/",
          },
        ],
      },
    ];

    const paperSection = [
      {
        title: "Currently Reading",
        books: [
          {
            title: "Bkd-Tree: A Dynamic Scalable kd-Tree",
            link:
              "https://users.cs.duke.edu/~pankaj/publications/papers/bkd-sstd.pdf",
            progressDate: "12th January 2024",
            description:
              "The Bkdtree is an I/O-efficient dynamic data structure based on the kd-tree. Stumbled upon this while exploring how Lucene internally indexes the data",
          },
        ],
      },
      {
        title: "Database Engineering",
        books: [
          {
            title: "Bkd-Tree: A Dynamic Scalable kd-Tree",
            link:
              "https://users.cs.duke.edu/~pankaj/publications/papers/bkd-sstd.pdf",
          },
          {
            title:
              "A Comprehensive Survey on Vector Database: Storage and Retrieval Technique, Challenge",
            link: "https://arxiv.org/pdf/2310.11703.pdf",
          },
        ],
      },
      {
        title: "Concurrency",
        books: [
          {
            title: "Understanding Real-World Concurrency Bugs in Go",
            link: "https://songlh.github.io/paper/go-study.pdf",
          },
        ],
      },
      {
        title: "Machine Learning",
        books: [
          {
            title: "Attention Is All You Need",
            link: "https://arxiv.org/pdf/1706.03762.pdf",
          },
          {
            title:
              "Sparks of Artificial General Intelligence: Early experiments with GPT - 4",
            link: "https://arxiv.org/pdf/2303.12712.pdf",
          },
        ],
      },
      {
        title: "Search Relevance",
        books: [
          {
            title:
              "Large language models can accurately predict searcher preferences",
            link: "https://arxiv.org/pdf/2309.10621.pdf",
          },
        ],
      },

      {
        title: "Data Structures",
        books: [
          {
            title:
              "A Bloom Filter Survey: Variants for Different Domain Applications",
            link: "https://arxiv.org/pdf/2106.12189.pdf",
          },
        ],
      },
    ];

    const sections =
      shelfType === "bookshelf"
        ? bookSections
        : shelfType === "papershelf"
        ? paperSection
        : "";

    return (
      <div className="BookShelf">
        <div className="shelf-title">
          <h1 className="title serif">{title}</h1>
          <p>{shelfDescription}</p>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            <nav className="menu">
              <p className="menu-label">Sections</p>
              <ul className="menu-list">
                {sections.map((section, index) => (
                  <li key={index}>
                    <a
                      href={`#${section.title
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="column">
            <div className="columns">
              {this.renderBookSection(sections[0].title, sections[0].books)}
            </div>
            <div className="columns">
              {sections.slice(1, 3).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.books)}
                </React.Fragment>
              ))}
            </div>
            <div className="columns">
              {sections.slice(3, 5).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.books)}
                </React.Fragment>
              ))}
            </div>
            <div className="columns">
              {sections.slice(5, 7).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.books)}
                </React.Fragment>
              ))}
            </div>
            <div className="columns">
              {sections.slice(7, 9).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.books)}
                </React.Fragment>
              ))}
            </div>
            <div className="columns">
              {sections.slice(9).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.books)}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BookShelf);
