import React, { Component } from "react";
import "firebase/firestore";
import "./MailBox.scss";
import firebase from "../../firebase-config";

class MailBox extends Component {
  state = { subscribed: false, mail: "" };
  handleInput = (e) => {
    this.setState({ mail: e.target.value });
  };
  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  subscribe = async () => {
    try {
      const mail = this.state.mail.trim(); // Remove leading and trailing whitespace
      if (!this.validateEmail(mail)) {
        return;
      }

      const db = firebase.firestore();
      await db.collection("emails").add({
        mail: this.state.mail,
        source: "akhileshk.in",
      });

      this.setState({ subscribed: true, mail: "" });
    } catch (error) {
      this.setState({ subscribed: false });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.subscribe();
    }
  };
  render() {
    return (
      <div className="MailBox">
        <label className="label">
          <span role="img" aria-label="rocket">
            🚀
          </span>{" "}
          Get my weekly essays!{" "}
        </label>
        <div className="field is-grouped">
          <p className="control is-expanded">
            <input
              className="input is-rounded"
              value={this.state.mail}
              onChange={this.handleInput}
              onKeyPress={this.handleKeyPress}
              placeholder="Email"
              type="email"
            />
          </p>
          <p className="control">
            <button onClick={this.subscribe} className="button is-rounded">
              Subscribe ➔ &nbsp;
              <span role="img" aria-label="popper">
                🎉
              </span>
            </button>
          </p>
        </div>
        <p className="help">
          I'll mail using my personal email. No Spam. No Automations.
          <span aria-label="star" role="img">
            💖.✨
          </span>
        </p>
        {this.state.subscribed && (
          <p className="help subscribed">
            Thank you, you are subscribed now!{" "}
            <span role="img" aria-label="fire">
              🔥
            </span>
          </p>
        )}
      </div>
    );
  }
}

export default MailBox;
