import React from "react";
import "./ResumeHeader.scss";

const ResumeHeader = ({
  websitewithhttp,
  website,
  linkedin,
  linkedinusername,
  github,
  githubusername,
  firstName,
  lastName,
  email,
  phone,
  address,
}) => {
  return (
    <div className="resume-header">
      <div className="resume-header__column">
        <a href={websitewithhttp} target="_blank" rel="noopener noreferrer">
          <div className="resume-header__icon">
            <i className="fas fa-globe"></i>
            <div className="resume-header__link">{website}</div>
          </div>
        </a>
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <div className="resume-header__icon">
            <i className="fab fa-linkedin"></i>
            <div className="resume-header__link">{linkedinusername}</div>
          </div>
        </a>
        <a href={github} target="_blank" rel="noopener noreferrer">
          <div className="resume-header__icon">
            <i className="fab fa-github"></i>
            <div className="resume-header__link">{githubusername}</div>
          </div>
        </a>
      </div>
      <div className="resume-header__column">
        <h1>
          <span className="first-name">{firstName}</span> {lastName}
        </h1>
      </div>
      <div className="resume-header__column">
        <div className="resume-header__icon">
          <i className="fas fa-envelope"></i>
          <div className="resume-header__contact">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>

        <div className="resume-header__icon">
          <i className="fas fa-phone"></i>
          <div className="resume-header__contact">
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </div>
        <div className="resume-header__icon">
          <i className="fas fa-map-marker-alt"></i>
          <div className="resume-header__contact">{address}</div>
        </div>
      </div>
    </div>
  );
};

export default ResumeHeader;
