import React, { Component } from "react";
import ReactGA from "react-ga";

import "./WhoamiPage.scss";

class WhoamiPage extends Component {
  getDay = () => {
    const days = [
      "Satisfying Sunday",
      "Marvelous Monday",
      "Terrific Tuesday",
      "Wonderful Wednesday",
      "Thoughtful Thursday",
      "Fun Friday",
      "Spectacular Saturday",
    ];
    const dayIndex = new Date().getDay();
    return days[dayIndex];
  };

  renderWorkExperience = () => {
    const workExperience = [
      {
        title: "Search Relevance Engineer @ Blibli.com",
        period: "Feb 2021 - Present",
      },
      {
        title: "Data Science @ Ericsson",
        period: "Feb 2020 - Jan 2021",
      },
      {
        title: "Automation Consultant @ Akvo",
        period: "May 2019 - Jan 2020",
      },
    ];

    return (
      <div className="column content">
        <h2 className="subtitle">Work Experience</h2>
        <ul>
          {workExperience.map((job, index) => (
            <li key={index}>
              <h6>{job.title}</h6>
              <p>{job.period}</p>
              {job.description && <p>{job.description}</p>}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  renderProudAchievements = () => {
    const proudAchievements = [
      {
        title: "World Rank 27, Brainwaves 2019",
        description:
          "Got 27/8134 rank in the ML contest on Hackerearth Website",
      },
      {
        title: "Founded MetaJUIT",
        link: "https://github.com/metajuit/",
      },
      {
        title: "Chaired the ACM Chapter",
        link: "https://juit.acm.org",
      },
      {
        title: "Founded the E-Cell, JUIT",
        link: "https://juit.acm.org",
      },
      {
        title: "Teaching Assistant",
        description:
          "TA for the Artificial Intelligence course (2019) at JUIT, Waknaghat",
      },
    ];

    return (
      <div className="column content">
        <h3 className="subtitle">Things I'm proud of</h3>
        <ul>
          {proudAchievements.map((achievement, index) => (
            <li key={index}>
              <h6>
                {achievement.link ? (
                  <a href={achievement.link}>{achievement.title}</a>
                ) : (
                  achievement.title
                )}
              </h6>
              {achievement.description && <p>{achievement.description}</p>}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  handleResumeDownload = () => {
    ReactGA.event({
      category: "Outgoing",
      action: "Download Resume",
    });
    window.location.href =
      "https://github.com/akhilesh-k/akhilesh-k.github.io/raw/master/resume.pdf";
  };

  render() {
    return (
      <div className="WhoamiPage">
        <h1 className="title center serif">whoami?</h1>
        <div className="info-section columns">
          <div className="column is-one-quarter">
            <figure className="image is-128x128">
              <img
                className="is-rounded"
                src="https://res.cloudinary.com/akhi/image/upload/v1692992199/profile_qdshfq.png"
                alt="profile"
              />
            </figure>
          </div>
          <div className="column">
            <p className="enlarge">
              I am a Software Engineer based in India. I love Engineering
              Systems and Building Products. Here's a list of things I'm reading
              about and projects I've worked on. This page serves mainly as a
              public accountability for me to consume and produce more ideas.
            </p>

            <div className="columns">
              {this.renderWorkExperience()}
              {this.renderProudAchievements()}
            </div>

            <button
              align="center"
              className="button is-black is-rounded"
              onClick={this.handleResumeDownload}
            >
              Download My Resume
            </button>
            <p className="wish-day">Have a {this.getDay()}!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default WhoamiPage;
