import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBZUjb_vfcYs5dcA-h5Qa0Y-zkexfltmNQ",
  authDomain: "akhileshk-c04fd.firebaseapp.com",
  databaseURL: "https://akhileshk-c04fd.firebaseio.com",
  projectId: "akhileshk-c04fd",
  storageBucket: "akhileshk-c04fd.appspot.com",
  messagingSenderId: "281032341202",
  appId: "1:281032341202:web:461638c99717fc46db1f0b",
  measurementId: "G-397KYWLNWP",
};

const app = initializeApp(firebaseConfig);
export default app;
