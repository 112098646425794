import React from "react";
import { withRouter } from "react-router-dom"; // Import withRouter
import Post from "../../components/PostComponent";
import "./PostPage.scss";

const PostPage = ({ match }) => {
  const essayId = match.params.id; // Get the essayId from match.params

  // Replace this URL with a dynamic source based on essayId
  const contentUrl = `https://raw.githubusercontent.com/akhilesh-k/blog-content/master/markdown/${essayId}.md`;

  return (
    <div>
      <Post contentUrl={contentUrl} />
    </div>
  );
};

export default withRouter(PostPage); // Use withRouter
