// components/Post.js
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import readingTime from "reading-time";
import "./Post.scss";

const Post = ({ contentUrl }) => {
  const [markdownContent, setMarkdownContent] = useState("");
  const [error, setError] = useState(null);
  const [sections, setSections] = useState([]);
  const [readTime, setReadTime] = useState("");

  useEffect(() => {
    // Fetch the Markdown content from the URL
    fetch(contentUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Unable to load the content!");
        }
        return response.text();
      })
      .then((data) => {
        setMarkdownContent(data);
        extractSections(data);
        calculateReadTime(data);

        // Add captions below images
        const updatedContent = addImageCaptions(data);
        setMarkdownContent(updatedContent);
      })
      .catch((error) => setError(error.message)); // Handle the error
  }, [contentUrl]);

  const addImageCaptions = (content) => {
    const imageRegex = /!\[([^\]]*)]\((.*?)\)/g;
    return content.replace(imageRegex, (match, alt, src) => {
      const caption = alt
        ? `<p class="image-alt" style="margin-top: 8px; text-align: center; font-size:16px; font-weight:400; line-height: 20px;color: #6B6B6B;">${alt}</p>`
        : "";
      return `<div class="img-cap"><img alt="${alt}" src="${src}" />${caption}</div>`;
    });
  };

  const calculateReadTime = (content) => {
    const result = readingTime(content);
    setReadTime(result.text);
  };

  const extractSections = (content) => {
    const headingRegex = /^#+\s+(.*)$/gm;
    const matches = content.match(headingRegex);
    if (matches) {
      const extractedSections = matches.map((match) =>
        match.replace(/#+\s+/g, "")
      );
      const sectionsToSet = extractedSections.slice(1);
      sectionsToSet.map((section) =>
        section.replace(/\s+/g, "-").replace(/[^a-zA-Z]/g, "")
      );
      setSections(sectionsToSet);
    }
  };

  const scrollToSection = (section) => {
    const sectionElement = document.getElementById(
      section.toLowerCase().replace(/\s+/g, "-")
    );
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (error) {
    return <div className="post-error">Error: {error}</div>;
  }

  return (
    <div className="post columns">
      <div className="sections">
        <p className="read-time">{readTime}</p>{" "}
        <p className="sections-label">Contents</p>{" "}
        <ul className="menu-list">
          {sections.map((section, index) => (
            <li key={index}>
              <a
                href={`#${section.toLowerCase().replace(/\s+/g, "-")}`}
                onClick={() => scrollToSection(section)}
              >
                {section}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="column post-content">
        {/* Display read time */}
        {/* Render the fetched Markdown content */}
        <ReactMarkdown
          children={markdownContent}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
        />
      </div>
    </div>
  );
};

export default Post;
