import React, { useEffect, useState } from 'react';
import {
    sortDateFunc,
    sortDateFuncReverse,
    convertMovingTime2Sec,
    formatPace,
    titleForRun,
    formatRunTime
} from '../../utils/utils';
import RunRow from './RunRow';
import styles from './style.module.css';

const RunTable = ({ runs = [], selectedYear, locateActivity, setActivity, runIndex, setRunIndex }) => {
    const [sortFuncInfo, setSortFuncInfo] = useState('');
    const [filteredAndSortedRuns, setFilteredAndSortedRuns] = useState([]);

    useEffect(() => {
        // Filter runs by selected year
        const getStartOfYear = (year) => new Date(`${year}-01-01`);
        const getEndOfYear = (year) => new Date(`${year + 1}-01-01`);

        const filteredRuns = runs.filter(run => {
            const runDate = new Date(run.start_date_local);
            return runDate >= getStartOfYear(selectedYear) && runDate < getEndOfYear(selectedYear);
        });

        // Sort filtered runs
        const sortFuncMap = new Map([
            ['Distance', sortKMFunc],
            ['Pace', sortPaceFunc],
            ['BPM', sortBPMFunc],
            ['Time', sortRunTimeFunc],
            ['Date', sortFuncInfo === 'Date' ? sortDateFunc : sortDateFuncReverse],
        ]);

        const sortFunc = sortFuncMap.get(sortFuncInfo) || (() => 0); // Default to no sorting if no func
        setFilteredAndSortedRuns(filteredRuns.sort(sortFunc));

    }, [runs, selectedYear, sortFuncInfo]);

    const sortKMFunc = (a, b) =>
        sortFuncInfo === 'Distance' ? a.distance - b.distance : b.distance - a.distance;

    const sortPaceFunc = (a, b) =>
        sortFuncInfo === 'Pace'
            ? a.average_speed - b.average_speed
            : b.average_speed - a.average_speed;

    const sortBPMFunc = (a, b) =>
        sortFuncInfo === 'BPM'
            ? (a.average_heartrate ?? 0) - (b.average_heartrate ?? 0)
            : (b.average_heartrate ?? 0) - (a.average_heartrate ?? 0);

    const sortRunTimeFunc = (a, b) => {
        const aTotalSeconds = convertMovingTime2Sec(a.moving_time);
        const bTotalSeconds = convertMovingTime2Sec(b.moving_time);
        return sortFuncInfo === 'Time'
            ? aTotalSeconds - bTotalSeconds
            : bTotalSeconds - aTotalSeconds;
    };

    const handleClick = (e) => {
        const funcName = e.target.textContent;
        setSortFuncInfo(sortFuncInfo === funcName ? '' : funcName);
    };

    return (
        <div className={styles.tableContainer}>
            <table className={styles.runTable} cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th />
                        {Array.from(new Map([
                            ['Distance', sortKMFunc],
                            ['Pace', sortPaceFunc],
                            ['BPM', sortBPMFunc],
                            ['Time', sortRunTimeFunc],
                            ['Date', sortFuncInfo === 'Date' ? sortDateFunc : sortDateFuncReverse],
                        ]).keys()).map((k) => (
                            <th key={k} onClick={handleClick}>
                                {k}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredAndSortedRuns.map((run, elementIndex) => (
                        <RunRow
                            key={run.run_id}
                            elementIndex={elementIndex}
                            locateActivity={locateActivity}
                            run={run}
                            runIndex={runIndex}
                            setRunIndex={setRunIndex}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RunTable;
