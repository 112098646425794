import React, { Component } from "react";
import Dashboard from "../../components/Dashboard";
import RunTable from "../../components/RunTable";
import useActivities from "../../hooks/useActivities";
import { INFO_MESSAGE } from "../../utils/const";
import { isMobileScreen } from "../../utils/utils";
import "./Running.scss";

class Running extends Component {
  constructor(props) {
    super(props);
    this.state = {
      runs: [],
      years: [],
      selectedYear: null,
      filteredRuns: [],
      isMobile: isMobileScreen(),
    };
  }

  componentDidMount() {
    const { activities, years } = useActivities();

    // Update state with fetched runs data and set the first year as selected
    this.setState({ years: years, selectedYear: years[0] });

    // Add event listener for screen resize to update isMobile state
    window.addEventListener("resize", this.handleResize);

    // Sort activities by date in descending order
    const sortedActivities = activities.sort((a, b) => {
      return (
        new Date(b.start_date_local.replace(" ", "T")).getTime() -
        new Date(a.start_date_local.replace(" ", "T")).getTime()
      );
    });

    // Filter the sorted activities by the initial selected year
    const filteredActivities = sortedActivities.filter(
      (activity) =>
        new Date(activity.start_date_local).getFullYear().toString() ===
        years[0]
    );

    // Update state with sorted and filtered activities
    this.setState({ runs: sortedActivities, filteredRuns: filteredActivities });
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: isMobileScreen() });
  };

  handleYearClick = (year) => {
    this.setState({ selectedYear: year }, () => {
      // Filter runs based on the selected year
      const filteredActivities = this.state.runs.filter(
        (activity) =>
          new Date(activity.start_date_local).getFullYear().toString() === year
      );
      this.setState({ filteredRuns: filteredActivities });
    });
  };

  render() {
    return (
      <div className="running">
        <div className="left-side-bar">
          <div>
            <h1 className="page-title">
              {this.state.isMobile ? "Run Training!" : "Running!"}
            </h1>
          </div>
          <div className="year-selector">
            {this.state.years.map((year, index) => (
              <span
                key={index}
                className={`year-item ${
                  year === this.state.selectedYear ? "selected" : ""
                }`}
                onClick={() => this.handleYearClick(year)}
              >
                {year}
              </span>
            ))}
          </div>
          <div className="page-subtitle">
            <h1>
              {INFO_MESSAGE(this.state.years.length, this.state.selectedYear)}
            </h1>
            <hr />
          </div>
          <Dashboard
            className="dashboard"
            selectedYear={this.state.selectedYear}
            isMobile={this.state.isMobile}
          />
        </div>
        <RunTable
          className="runtable"
          runs={this.state.filteredRuns}
          selectedYear={this.state.selectedYear}
          locateActivity={() => {}}
          setActivity={(activities) => this.setState({ runs: activities })}
          runIndex={-1}
          setRunIndex={() => {}}
        />
      </div>
    );
  }
}

export default Running;
