import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Essays.scss";

class Essays extends Component {
  currentMonthInWords() {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    return month;
  }
  renderBookSection(title, books, clickable = true) {
    const handleTitleClick = (essayId) => {
      if (clickable) {
        this.props.history.push(`/essays/${essayId}`);
      }
    };
    return (
      <div
        className="column content"
        id={title.replace(/\s+/g, "-").toLowerCase()}
      >
        <h3 className="subtitle">{title}</h3>
        <ul>
          {books.map((book, index) => (
            <li key={index}>
              <h6>
                <div
                  className="link-title"
                  onClick={() =>
                    handleTitleClick(
                      book.title.replace(/\s+/g, "-").toLowerCase()
                    )
                  }
                >
                  {book.title}
                </div>
              </h6>
              <p>{book.description}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const sections = [
      {
        title: "Currently Writing / WIP",
        blogs: [
          {
            title:
              "The math behind Inverse Document Frequency - the IDF of TFIDF",
            id: 'how-does-the-hsnw-algorithm-work',
            description:
              "TF-IDF is one of the most popular measures that quantify document relevance for a given term. It is extensively used in Information Retrieval (ex: Search Engines), Text Mining and even for text-heavy Machine Learning use cases like Document Classification and Clustering.",
          },
        ],
      },
      {
        title: "Career Growth and Productivity",
        blogs: [
          {
            title: "Back to learning deeply",
            id: "file:///Users/akhileshk/Development/blog-content/markdown/going-back-to-learning-deeply.md",
          },
          {
            title: "Becoming a Better Software Engineer",
          },
        ],
      },
      {
        title: "Search Relevance and IR",
        blogs: [
          {
            title: "How does the HNSW algorithm work?",

            id: "how-does-the-hsnw-algorithm-work",
          },
          {
            title: "Is Hybrid Search the future of Ecommerce search?",
          },
          {
            title: "Building Vector Search application on top of Solr?",
          },
          {
            title: "How to quanitfy the search results quality?",
          },
        ],
      },
      {
        title: "Scalable Systems Design",
        blogs: [
          {
            title:
              "Understanding the difference between load balancer, reverse proxy and API gateway",
          },
          {
            title:
              "Designing and implementing a sliding window based rate limiter",
          },
        ],
      },
      {
        title: "Concurrency and Multithreading",
        blogs: [
          {
            title: "Navigating Concurrent Updates with Optimistic Locking",
          },
        ],
      },

      {
        title: "Advanced Data Structures and Algorithms",
        blogs: [
          {
            title: "Bloom Filters Explained",
          },
          {
            title:
              "Understanding and Implementing the Count-Min Sketch Data Structure",
          },
          {
            title:
              "Understanding and Implementing the HyperLogLog Data Structure",
          },
          {
            title:
              "Understanding and Implementing the Skip List Data Structure",
          },
          {
            title:
              "Unlocking the Power of Trie Structures: Applications Beyond Text Search",
          },
        ],
      },
      {
        title: "Programming",
        blogs: [],
      },
    ];

    return (
      <div className="BlogPage">
        <div className="shelf-title">
          <h1 className="title serif">
            Essays and Blogs
          </h1>
          <p>Documenting my learnings through one essay at a time.</p>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            <nav className="menu">
              <p className="menu-label">Sections</p>
              <ul className="menu-list">
                {sections.map((section, index) => (
                  <li key={index}>
                    <a
                      href={`#${section.title
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="column">
            <div className="columns">
              {this.renderBookSection(
                sections[0].title,
                sections[0].blogs,
                false
              )}
            </div>
            <div className="columns">
              {sections.slice(1, 3).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.blogs)}
                </React.Fragment>
              ))}
            </div>
            <div className="columns">
              {sections.slice(3, 5).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.blogs)}
                </React.Fragment>
              ))}
            </div>
            <div className="columns">
              {sections.slice(5).map((section, index) => (
                <React.Fragment key={index}>
                  {this.renderBookSection(section.title, section.blogs)}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Essays);
