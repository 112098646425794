import React from 'react';
import { formatPace, titleForRun, formatRunTime, isMobileScreen } from '../../utils/utils';
import styles from './style.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const RunRow = ({ elementIndex, locateActivity, run, runIndex, setRunIndex }) => {
    const distance = (run.distance / 1000.0).toFixed(2);
    const paceParts = run.average_speed ? formatPace(run.average_speed) : null;
    const heartRate = run.average_heartrate;
    const runTime = formatRunTime(run.moving_time);

    // Check if the screen is mobile
    const isMobile = isMobileScreen();

    // Format date based on mobile or desktop screen
    const formattedDate = isMobile
        ? new Date(run.start_date_local).toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric',
        }) // Mobile - shorter format
        : new Date(run.start_date_local).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }); // Desktop - full format

    const handleClick = () => {
        if (runIndex === elementIndex) {
            setRunIndex(-1);
            locateActivity([]);
            return;
        }
        setRunIndex(elementIndex);
        locateActivity([run.run_id]);
    };

    return (
        <tr
            className={`${styles.runRow} ${runIndex === elementIndex ? styles.selected : ''}`}
            key={run.start_date_local}
            onClick={handleClick}
        >
            <td>
                <Link
                    to={{
                        pathname: `https://www.strava.com/activities/${run.run_id}`,
                    }}
                    target="_blank"
                >
                    {titleForRun(run)}
                </Link>
            </td>
            <td>{distance}</td>
            {paceParts && <td>{paceParts}</td>}
            <td>{heartRate && heartRate.toFixed(0)}</td>
            <td>{runTime}</td>
            <td className={styles.runDate}>{formattedDate}</td> {/* Use the formatted date */}
        </tr>
    );
};

export default RunRow;
