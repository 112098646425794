import React, { Component } from "react";

import "./Footer.scss";

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer className=" footer Footer">
        <br />

        <p className="footer-subtitle">Akhilesh K © 2016 - {currentYear}</p>
      </footer>
    );
  }
}

export default Footer;
