import React, { Component } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = { isActive: false };

  toggleActive = (event) => {
    if (event.target.id === "brand") {
      if (this.state.isActive) {
        this.setState((prevState) => ({ isActive: !prevState.isActive }));
      }
    } else {
      this.setState((prevState) => ({ isActive: !prevState.isActive }));
    }
  };

  renderNavLink(to, label) {
    return (
      <Link to={to} className="navbar-item" onClick={this.toggleActive}>
        {label}
      </Link>
    );
  }

  render() {
    const { isActive } = this.state;

    return (
      <nav className="Navbar">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/" onClick={this.toggleActive}>
              <h1 className="title serif" id="brand">
                akhileshk.in
              </h1>
            </Link>

            <div
              role="button"
              className={`navbar-burger ${isActive ? "is-active" : ""}`}
              onClick={this.toggleActive}
              aria-label="menu"
              aria-expanded={isActive}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
            <div className="navbar-end">
              {this.renderNavLink("/essays", "essays")}
              {this.renderNavLink("/bookshelf", "bookshelf")}
              {this.renderNavLink("/papershelf", "papershelf")}
              {/* {this.renderNavLink("/biking", "biking")} */}
              {this.renderNavLink("/running", "running")}
              {/* {this.renderNavLink("/about-akhilesh-kumar", "whoami")} */}
              {this.renderNavLink("/resume", "resume")}
            </div>
          </div>
        </nav>
      </nav>
    );
  }
}

export default Navbar;
