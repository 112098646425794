import React from 'react';
import { intComma } from '../../utils/utils';
import "./Stat.scss";

const Stat = ({
    value,
    description,
    citySize,
    onClick,
}) => (
    <div className='Stat' onClick={onClick}>
        <span className='values'>
            {intComma(value.toString())}
        </span>
        <span className="legends">{description}</span>
    </div>
);

export default Stat;
