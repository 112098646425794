import React from "react";
import "../Stat/Stat.scss";
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const getTotalDistance = (runs, startDate, endDate = new Date()) => {
  return (
    runs
      .filter(
        (run) =>
          new Date(run.start_date_local) >= startDate &&
          new Date(run.start_date_local) <= endDate
      )
      .reduce((total, run) => total + (run.distance || 0), 0) / 1000
  ); // Convert to kilometers
};

const calculateTotalMovingTime = (runs, startDate, endDate = new Date()) => {
  // Helper function to convert "H:M:S" into total seconds
  const timeToSeconds = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
  };

  // Filter runs by date and calculate total seconds
  const totalSeconds = runs
    .filter(
      (run) =>
        new Date(run.start_date_local) >= startDate &&
        new Date(run.start_date_local) <= endDate
    )
    .reduce((acc, run) => {
      return acc + timeToSeconds(run.moving_time);
    }, 0);

  // Convert total seconds to "H h M m S sec" format
  const secondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Build the formatted time string
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours}h `;
    }
    if (minutes > 0) {
      timeString += `${minutes}m `;
    }
    if (seconds > 0 || timeString === "") {
      // Always show seconds if no hours/minutes
      timeString += `${seconds} sec`;
    }

    return timeString.trim();
  };

  return secondsToTime(totalSeconds);
};


const calculateDates = () => {
  const now = new Date();

  // Start dates
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay());

  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  const startOfLastWeek = new Date(startOfWeek);
  startOfLastWeek.setDate(startOfWeek.getDate() - 7);

  const startOfLastMonth = new Date(startOfMonth);
  startOfLastMonth.setMonth(startOfLastMonth.getMonth() - 1);

  // End dates
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const endOfLastWeek = new Date(startOfLastWeek);
  endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);

  const endOfLastMonth = new Date(startOfMonth);
  endOfLastMonth.setDate(0);  // Last day of the previous month

  return {
    startOfWeek, endOfWeek,
    startOfMonth, endOfMonth,
    startOfLastWeek, endOfLastWeek,
    startOfLastMonth, endOfLastMonth
  };
};


const RunningStats = ({ runs }) => {
  const {
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfLastWeek,
    endOfLastWeek,
    startOfLastMonth,
    endOfLastMonth
  } = calculateDates();

  const totalDistanceWeek = getTotalDistance(runs, startOfWeek, endOfWeek);
  const totalDurationWeek = calculateTotalMovingTime(runs, startOfWeek, endOfWeek);
  const totalDistanceMonth = getTotalDistance(runs, startOfMonth, endOfMonth);
  const totalDurationMonth = calculateTotalMovingTime(runs, startOfMonth, endOfMonth);
  const totalDistanceLastWeek = getTotalDistance(runs, startOfLastWeek, endOfLastWeek);
  const totalDurationLastWeek = calculateTotalMovingTime(runs, startOfLastWeek, endOfLastWeek);
  const totalDistanceLastMonth = getTotalDistance(runs, startOfLastMonth, endOfLastMonth);
  const totalDurationLastMonth = calculateTotalMovingTime(
    runs,
    startOfLastMonth,
    endOfLastMonth
  );

  // const data = [
  //     { name: 'This Week', distance: totalDistanceWeek / 1000 },
  //     { name: 'Last Week', distance: totalDistanceLastWeek / 1000 },
  //     { name: 'This Month', distance: totalDistanceMonth / 1000 },
  //     { name: 'Last Month', distance: totalDistanceLastMonth / 1000 },
  // ];

  const style = {
    flexDirection: "column",
  };
  return (
    <div className="Stat" style={style}>
      <h2 className="values">Current Stats</h2>
      <p className="legends">This Week</p>
      <p className="values-m">
        {" "}
        {totalDistanceWeek.toFixed(2)} KM
        <span className="values-s"> • {totalDurationWeek}</span>
      </p>
      <p className="legends">This Month</p>
      <p className="values-m">
        {" "}
        {totalDistanceMonth.toFixed(2)} KM{" "}
        <span className="values-s"> • {totalDurationMonth}</span>
      </p>
      <p className="legends">Last Week</p>
      <p className="values-m">
        {" "}
        {totalDistanceLastWeek.toFixed(2)} KM
        <span className="values-s"> • {totalDurationLastWeek}</span>
      </p>
      <p className="legends">Last Month</p>
      <p className="values-m">
        {totalDistanceLastMonth.toFixed(2)} KM
        <span className="values-s"> • {totalDurationLastMonth}</span>
      </p>
      <hr />
      <div>
        {/* <LineChart
                    width={600}
                    height={300}
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="distance" stroke="#8884d8" />
                </LineChart> */}
      </div>
    </div>
  );
};

export default RunningStats;
