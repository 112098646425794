import { locationForRun, titleForRun } from '../utils/utils.js';
import activities from '../static/activities.json';

const useActivities = () => {
    const cities = {};
    const runPeriod = {};
    const provinces = new Set();
    const countries = new Set();
    let years = new Set();
let thisYear = '';

    activities.forEach((run) => {
        // console.log(run)
        const location = locationForRun(run);

        const periodName = titleForRun(run);
        if (periodName) {
            runPeriod[periodName] = runPeriod[periodName]
                ? runPeriod[periodName] + 1
                : 1;
        }

        const { city, province, country } = location;
        // drop only one char city
        if (city.length > 1) {
            cities[city] = cities[city] ? cities[city] + run.distance : run.distance;
        }
        if (province) provinces.add(province);
        if (country) countries.add(country);
        const year = run.start_date_local.slice(0, 4);
        years.add(year);
    });

    let yearsArray = [...years].sort().reverse();
    if (yearsArray.length) [thisYear] = yearsArray; // set current year as first one of years array

    return {
        activities,
        years: yearsArray,
        countries: [...countries],
        provinces: [...provinces],
        cities,
        runPeriod,
        thisYear,
    };
};

export default useActivities;
