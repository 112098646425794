import React from "react";
import "./ResumeItems.scss";

const ResumeItems = ({ experiences }) => {
  return (
    <div className="resume-items">
      {experiences.map((experienceGroup, groupIndex) => (
        <div className="experience-group" key={groupIndex}>
          <div className="experience-group__title"> {experienceGroup.type}</div>
          {experienceGroup.items.map((experience, index) => (
            <div className="resume-item" key={index}>
              <div className="resume-item-header">
                <div className="resume-item-title">
                  <h4 className="resume-item-company">
                    <a href={experience.link} target="_blank" rel="noopener noreferrer">
                      {experience.company}
                    </a>
                  </h4>
                  <h5 className="resume-item-role">{experience.role}</h5>
                </div>
                {experience.start_date !== undefined && (
                  <div className="resume-item-date">
                    <h6 className="resume-item-start-date">
                      {experience.start_date} - {experience.end_date}
                    </h6>
                  </div>
                )}
              </div>
              {experience.descriptions !== undefined && (
                <div className="resume-item-body">
                  <ul className="resume-item-description">
                    {experience.descriptions.map((description, idx) => (
                      <li key={idx}>{description}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ResumeItems;
